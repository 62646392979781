import styled from "styled-components";

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  background: ${({ chapters }) =>
    chapters.length === 0 ? "rgba(255, 255, 255, 0.2)" : "none"};
  cursor: pointer;
  margin: 8px 0;
  z-index: 999;
  &:hover {
    transform: scaleY(1.4);
  }
`;

export const Progress = styled.div`
  position: absolute;
  height: 100%;
  background: ${({ progressColor }) =>
    progressColor ? progressColor : " #413FD7"};
  transition: all 0.12s linear;
`;

export const BufferProgress = styled.div`
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transition: width 0.3s linear;
`;

export const ProgressThumb = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: #413fd7;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  cursor: pointer;
  transition: width 0.1s linear !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  // &:hover {
  //   transform: scale(-1.8) !important;
  // }

  /* Only show the thumb when hovering over the progress bar or when dragging */
  display: none;
  ${ProgressContainer}:hover &,
  ${ProgressContainer}:active & {
    display: block;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 20px;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  transform: translateX(-50%);
  display: none;

  ${ProgressContainer}:hover & {
    display: block;
  }
`;

export const ChapterPointsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  // top: 7px;
  left: 0;
  pointer-events: auto;
  gap: 3px;
  &:hover {
    // transform: scaleY(1.8);
  }
`;

export const PointsItem = styled.div`
  width: ${(props) => props.elementWidth || "0"}%;
  height: 5px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  // opacity: 0.5;
  transition: width 0.1s linear;
  &:hover {
    transform: scaleY(1.2);
  }
`;

export const BufferProgressSegment = styled(PointsItem)`
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  // trasition:width 0.1s linear
  // z-index: 1;
`;

export const ProgressSegment = styled(PointsItem)`
  background-color: ${({ progressColor }) =>
    progressColor ? progressColor : " #413FD7"};
  position: absolute;
  // trasition:width 0.12s linear
  // z-index: 2;
`;
