import styled, { css, keyframes } from "styled-components";
import theme from "../../theme";

const slideIn = keyframes`
  from {
    width: 236px;
  }
  to {
    width: 97px;
  }
`;
const slideOut = keyframes`
  from {
    width: 179px;
  }
  to {
    width: 236px;
  }
`;
export const SidebarContainer = styled.div`
  padding-top: ${(props) => (props.side ? "30%" : "10%")};
  position: relative;
  z-index: 3;
  left: 0;
  top: 0;
  width: ${(props) => (props.side ? "97px" : "266px")};
  background-color: ${theme.colors.sidebar};
  height: 100%;
  border-right: 1px solid ${theme.colors.rightBorder};
  /* padding:${(props) => (props.side ? "0" : "0 20px")}; */
  ${(props) =>
    props.side
      ? css`
          animation: ${slideIn} 0.3s linear;
        `
      : css`
          animation: ${slideOut} 0.3s linear;
        `}
  .sidebtn {
    cursor: pointer;
    position: absolute;
    top: 126px;
    right: -13px;
  }
  a {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 10% 0;
  }
  @media (max-width: 1220px) {
    display: ${(props) => (props.hum === "Video" ? "none" : "")};
    position: ${(props) => (props.hum !== "Video" ? "absolute" : "")};
  }
`;

export const Recorder = styled.img`
  /* margin-left: 21px; */
  width: 138.345px;
  height: 32px;
  cursor: pointer;
  ${theme.breakpoints.tab} {
  width: 100px;
  height: 32px;
  }
  ${theme.breakpoints.smallView} {
    display: none;
  }
&.present{
  position: absolute;
  top: 19px;
  left: 26px;
}
`;

export const RecorderSmall = styled.img`
  /* margin-left: 21px; */
  width: 138.345px;
  height: 32px;
  cursor: pointer;
  display: none;
  ${theme.breakpoints.smallView} {
    display: block;
    width: 100px;
  height: 22px;
  }

`;

export const Heading = styled.p`
  color: #a18e97;
  font-weight: 400;
  font-size: 14px;
  margin-left: ${(props) => (props.side ? "0" : "22px")};
  margin-bottom: ${(props) => (props.side ? "21" : "5px")};
  &.playlist {
    margin-top: 47px;
    @media (max-width: 1780px) {
      margin-top: 10px;
    }
  }
`;
export const ToolTipWrap = styled.div`
  display: none;
  position: absolute;
  margin-left: 144px;
  top: 12px;

  img {
    margin-top: 8px;
    width: 6px;
    margin-left: 7px;
  }
`;
export const Commission = styled.h1`
 position: absolute;
  top: 30%;
  left: 72%;
  transform: translate(-50%,-50%);
  padding: 12px 48px;
  color: #6528F7;
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #6528F7 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 11px;
  text-decoration: none;
  white-space: nowrap;
    
  @keyframes shine{
  0%{
    background-position: 0
  }
  60%{
    background-position: 180px
  }
  100%{
    background-position: 180px
  }
  }
`
export const Menu = styled.div`
  position: relative;
  width: 100%;
  /* background-color: green; */
  padding: ${(props) => (props.side ? "8.8% 0" : "5%")};
  padding-left: ${(props) => (props.side ? "0" : "22px")};
  display: flex;
  flex-direction: ${(props) => (props.side ? "column" : "row")};
  border-radius: 4px;
  border-radius: 0px 4px 4px 0px;
  align-items: ${(props) => (props.side ? "center" : "center")};
  cursor: pointer;
  color: ${theme.colors.placeHolder};
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .premium {
    margin-left: 13.99px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  &:hover ${ToolTipWrap} {
    display: flex !important;
  }
  &:hover {
    background-color: #f5f5f5;

    /* p{
        color:${(props) => props.theme.colors.hover};  
    }  */
  }
  &.active {
    /* background-color:#E8E6FC ; */
    color: ${theme.colors.lightText1};

    p {
      color: ${theme.colors.lightText1};
    }
  }
  img {
    height:${(props)=>!props?.brand&&'16px'};
    /* width: 16px; */
  }
  p {
    color: ${(props) => props.theme.colors.placeHolder};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    &.playlist {
      width: ${(props) => (props.side ? "3ch" : "100%")};
      overflow: hidden;
      flex-direction: column;
    }
  }
  .Rewards {
    
  }
`;

export const ToolTip = styled.div`
  padding: 4px 8px;
  background: rgba(102, 112, 133, 0.7);
  width: max-content;
  height: 26px;
  color: var(--extra-white, #fff);
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 2px;
`;
export const MenuImgWrap = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: ${theme.colors.lightPurple};
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  margin-right: ${(props) => (props.side ? "0" : "10px")};
`;
export const MenuImgWrapclr = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
  margin-right: ${(props) => (props.side ? "0" : "10px")};
  .collapseTrans {
    transform: ${(props) => (props.side ? "scaleX(-1)" : "")};
  }
`;
export const MenuSection = styled.div`
  width: 100%;
  text-align: ${(props) => (props.side ? "center" : "left")};
  display: flex;
  flex-wrap: wrap;
  /* border-bottom: ${(props) =>
    props.side ? "none" : "1px solid #E6E6E6"}; */

  @media (min-width: 1780px) {
    display: block;
  }
  @media (max-width: 1200px) {
    margin: auto;
  }
`;
export const Pages = styled.div`
  width: 100%;
  height: 30%;
  margin-bottom: 20px;
`;

export const Settings = styled.div`
  width: 100%;
  height: 25%;
  padding-bottom: 2%;
`;
export const Recording = styled.div`
  text-align: start;
  margin-left: 17px;
  display: ${(props) => (props.side ? "none" : "block")};
  .free {
    font-size: 14px;
    color: #939090;
    line-height: 17.19px;
    margin-bottom: 21px;
  }
`;
export const BarContainer = styled.div`
  .number {
    color: ${(props) => props.theme.colors.title1};
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 8px;
  }
`;
export const Bar = styled.div`
  width: 152px;
  height: 5px;
  border-radius: 4px;
  background-color: #dce6ff;
  position: relative;
  .color {
    position: absolute;
    background-color: #6a36f0;
    top: 0;
    left: 0;
    width: ${(props) => props.percentage}%;
    height: 5px;
    border-radius: 4px;
  }
`;
export const DesktopApp= styled.div`
display: flex;
justify-content: center;
align-items: center;
`
export const DesktopAppCont= styled.div`
width: 190px;
height: 194px;
background-color: #F7F8FA;
border-radius: 16px;
padding: 14px;
display: flex;
flex-direction: column;
position: relative;
`
export const MainHeaderDesktop = styled.div`
font-family: 'Inter';
font-size: 12px;
font-weight: 600;
line-height: 17.19px;
text-align: left;
color: #393939;

`
export const SubHeadDesktop = styled.div`
font-family: 'Inter';
font-size: 12px;
font-weight: 400;
line-height: 15px;
text-align: left;
color: #667085;
margin-top: 10px;

`
export const DeskAppIcon = styled.img`
margin-top: 10px;
`
export const DownloadBtn = styled.a`
font-family: 'Inter';
font-size: 12px !important;
font-weight: 400;
line-height: 12px;
text-align: left;
color: #6528F7;
margin-top: 10px;
display: flex !important;
justify-content: flex-end;
cursor: pointer;
`
export const DeskCrossIcon = styled.img`
width: 14px;
height: 14px;
position: absolute;
top: 7px;
right: 15px;
cursor: pointer;
`
export const Review = styled.div`
  position: absolute;
  bottom: 25px;
  /* left: 20px; */
  /* right: 20px; */
  padding: 32px 0;
  width: 100%;
  /* display: ${(props) => (props.side ? "none" : "block")}; */
  color: ${theme.colors.placeHolder};

  hr {
    margin-top: 30px;
    margin-bottom: 37px;
    border-top: 1px solid #e6e6e6;
    @media (max-height: 680px) {
      margin-top: 20px;
      margin-bottom: 27px;
    }
  }
  @media (min-width: 1780px) {
    display: block;
  }
  @media (max-height: 680px) {
    padding-top: 20px;
  }
  .contents {
    cursor: pointer;
    display: ${(props) => (props.side ? "none" : "flex")};
    align-items: center;
    margin-left: 17px;
    margin-bottom: 29px;
    @media (max-height: 680px) {
      margin-bottom: 20px;
    }
    .menu {
      img {
        margin-right: 13.69px;
        height: 24px;
        width: 24px;
      }
      font-size: 14px;
      margin: 0;
      background: #585763;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &.upgrade {
        background: linear-gradient(91.36deg, #6c5dd3 0%, #585763 99.48%);
        background-size: 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: colorchange 1s linear infinite;
        @keyframes colorchange {
          0% {
            background-position: 0%;
          }
          100% {
            background-position: 200%;
          }
        }
      }
    }
  }
`;

export const PlaylistWrap = styled.div`
  max-height: 240px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    width: 100%;
  }
  ::-webkit-scrollbar-thumb {
    display: ${(props) => (props.play === "in" ? "block" : "none")};
    background-color: #d9d9d9;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-button:single-button {
    height: 20px;
  }
  @media (max-width: 1780px) {
    max-height: 210px;
  }
`;
export const BlackScreen = styled.div`
  @media (max-width: 1220px) {
    height: ${(props) => (props.hum === "Video" ? "" : "100%")};
    width: ${(props) => (props.hum === "Video" ? "" : "100vw")};
    position: ${(props) => (props.hum === "Video" ? "" : "absolute")};
    background: ${(props) => (props.hum === "Video" ? "" : "rgba(30, 33, 38, 0.60)")};
    z-index: ${(props) => (props.hum === "Video" ? "" : "1")};
  }
`;
export const Wrapper = styled.div`
  @media (max-width: 1220px) {
    height: ${(props) => (props.hum === "Video" ? "" : "100%")};
    position: ${(props) => (props.hum === "Video" ? "" : "relative")};
    z-index: ${(props) => (props.hum === "Video" ? "" : "2")};
    padding-top: ${(props) => (props.hum !== "Video" ? "30px" : "30px")};

    background: ${(props) => (props.hum === "Video" ? "" : "#f7f8fa")};
  }
`;
