import styled, { keyframes } from "styled-components";

export const EmojiAndComentsContainer = styled.div`
  position: absolute;
  top: 24%;
  left: 50%;
  // width: 100%;
  transform: translateX(-50%);

  @media (max-width: 668px) {
    display: none;
  }

  @media (max-width: 1180px) {
    top: ${({ isComment }) => isComment && "30%"};
  }

  // @media (max-width: 920px) {
  //   left: 30%;
  // }

  @media (max-width: 806px) {
    left: 55%;
  }
`;

export const EmojiAndComentsContainerOutsideVideo = styled.div`
  position: absolute;
  bottom: ${({ videoPath }) =>
    videoPath.includes("capture")
      ? "-10%"
      : videoPath.includes("workspace")
      ? "-16%"
      : "-14%"};
  left: ${({ videoPath, isComment }) =>
    videoPath.includes("capture")
      ? "50%"
      : videoPath.includes("workspace")
      ? "26%"
      : !isComment
      ? "62%"
      : "41%"};
  transform: ${({ videoPath }) =>
    videoPath.includes("capture") && "translateX(-50%)"};
  z-index: 99;

  // @media (max-width: 1310px) {
  //   left:${({ videoPath }) =>
    videoPath.includes("library") ? "52%" : "auto"};
  // };

  @media (max-width: 1430px) {
    left: ${({ videoPath }) =>
      videoPath.includes("capture")
        ? "50%"
        : videoPath.includes("workspace")
        ? "26%"
        : "52%"};
  }

  @media (max-width: 1640px) {
    bottom: ${({ videoPath }) => videoPath.includes("capture") && "-12%"};
  }

  @media (max-width: 668px) {
    display: none;
  }

  @media (max-width: 770px) {
    bottom: ${({ videoPath }) => videoPath.includes("library") && "-21%"};
  }
`;

export const EmojiAndComentsWrapper = styled.div`
  // position: absolute;
  top: 117px;
  left: 50px;
  display: flex;
  background: black;
  padding: 6px;
  gap: 18px;
  border-radius: 48px;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  // border: 1px solid white;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (max-width: 1310px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const VideoInsideEmojiContainer = styled.div`
  display: flex;
`;
// export const VideoInsdieEmojiSubContainer = styled.div`
// position: absolute;
// top: -23px;
// left: ${({ element, totalTime }) => {
//   const percentage = (parseFloat(element.videoTime) / totalTime) * 100;
//   return `${percentage > 95 ? Math.ceil(percentage - 1) : percentage}%`;
// }};
// opacity: ${({ currentTime, element, showReactions }) =>
//     (Math.floor(currentTime) === parseInt(element.videoTime) || element.type === "comment" || showReactions) ? 1 : 0};
//     transform:${({element,currentTime, showReactions})=> ( (Math.floor(currentTime) === parseInt(element.videoTime)) && element?.type==="emoji" && !showReactions) && "translateY(-16px)"};
//     transition:${({element,currentTime, showReactions})=> ( (Math.floor(currentTime) === parseInt(element.videoTime)) &&  element?.type ==="emoji" && !showReactions) && "all 0.4s  ease-in-out"};
// // transition: opacity 0.3s ease-in-out;
// font-size: 24px;
// display: flex;
// width: 100%;`;

export const VideoInsdieEmojiSubContainer = styled.div`
  position: absolute;
  top: -23px;
  left: ${({ element, totalTime }) => {
    const percentage = (element.videoTime / totalTime) * 100;
    return `${percentage > 95 ? Math.ceil(percentage - 1) : percentage}%`;
  }};
  opacity: ${({ currentTime, element, showReactions }) =>
    (currentTime >= parseFloat(element.videoTime) &&
      currentTime <= parseFloat(element.videoTime) + 0.8) ||
    element.type === "comment" ||
    showReactions
      ? 1
      : 0};
  transform: ${({ element, currentTime, showReactions }) =>
    currentTime >= parseFloat(element.videoTime) &&
    currentTime <= parseFloat(element.videoTime) + 0.8 &&
    element?.type === "emoji" &&
    !showReactions &&
    "translateY(-16px)"};
  transition: all 0.4s ease-in-out;
  font-size: 24px;
  display: flex;
  width: 100%;
  animation: ${({ currentTime, element, showReactions }) =>
    currentTime >= parseFloat(element.videoTime) &&
    currentTime <= parseFloat(element.videoTime) + 0.8 &&
    element?.type === "emoji" &&
    !showReactions
      ? "popIn 0.4s ease-in-out"
      : "none"};

  @keyframes popIn {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-16px);
    }
    100% {
      opacity: 1;
      transform: translateY(-16px);
    }
  }
`;

export const CommonlyUsedEmojis = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 20px;

  div {
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
    }
  }

  @media (max-width: 1310px) {
    font-size: 18px;
    gap: 14px;
  }
`;

export const AddReactions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 25px;
    height: 25px;
  }
`;

export const CommnetInputContainer = styled.div`
  display: flex;
  // position: absolute;
  top: 117px;
  left: 50px;
  display: flex;
  gap: 4px;
  align-items: center;
  // width: 35%;
`;

export const CommnetInputContainerOutsideVideo = styled.div`
  display: flex;
  // position: absolute;
  // top: 1%;
  // left: -20%;
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;
`;
export const CommentForm = styled.div`
  display: flex;
  gap: 4px;
  width: ${({ videoPath, element }) =>
    videoPath?.includes("workspace") && element === "outside"
      ? "233px"
      : "366px"};
  border-radius: ${({ element }) => (element === "inside" ? "30px" : "40px")};
  background: #000;
  padding: ${({ element }) => (element === "inside" ? "2px 4px" : "6px 2px")};
  justify-content: space-around;
  align-items: center;
  // border: 1px solid white;
  height: ${({ videoPath, element }) =>
    videoPath?.includes("workspace") && element === "outside"
      ? "36px"
      : "44px"};
  font-size: ${({ videoPath, element }) =>
    videoPath?.includes("workspace") && element === "outside" ? "8px" : "12px"};
  padding: 6px 1px;

  input {
    width: 60%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 6px;
    font-family: Inter;
    color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div {
    width: 30%;
    height: 100%;
    border-radius: 30px;
    background: white;
    color: black;
    border: none;
    padding: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    color: rgba(0, 0, 0, 0.9);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 1430px) {
    width: ${({ videoPath, element }) =>
      !videoPath?.includes("workspace") && element !== "inside" && "242px"};
    height: ${({ videoPath, element }) =>
      !videoPath?.includes("workspace") && element !== "inside" && "36px"};
  }
  @media (max-width: 1180px) {
    width: ${({ videoPath, element }) =>
      (!videoPath?.includes("workspace") || element === "inside") && "233px"};
    height: ${({ videoPath, element }) =>
      (!videoPath?.includes("workspace") || element === "inside") && "36px"};
    font-size: ${({ videoPath, element }) =>
      (!videoPath?.includes("workspace") || element === "inside") && "8px"};
  }
`;

export const CancelButton = styled.div`
  width: ${({ videoPath, element }) =>
    videoPath?.includes("workspace") && element === "outside"
      ? "64px"
      : "101px"};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: rgba(0, 0, 0, 0.9);
  // border: 1px solid white;
  cursor: pointer;
  color: white;
  padding: 10px;
  font-family: Inter;
  font-size: ${({ videoPath, element }) =>
    videoPath?.includes("workspace") && element === "outside" ? "8px" : "12px"};
  height: ${({ videoPath, element }) =>
    videoPath?.includes("workspace") && element === "outside"
      ? "36px"
      : "44px"};

  img {
    width: ${({ videoPath, element }) =>
      videoPath?.includes("workspace") && element === "outside"
        ? "9px"
        : "12px"};
    height: ${({ videoPath, element }) =>
      videoPath?.includes("workspace") && element === "outside"
        ? "9px"
        : "12px"};
  }
  @media (max-width: 1430px) {
    width: ${({ videoPath, element }) =>
      !videoPath?.includes("workspace") && element !== "inside" && "80px"};
    height: ${({ videoPath, element }) =>
      !videoPath?.includes("workspace") && element !== "inside" && "36px"};
  }
  @media (max-width: 1180px) {
    width: ${({ videoPath, element }) =>
      (!videoPath?.includes("workspace") || element === "inside") && "64px"};
    height: ${({ videoPath, element }) =>
      (!videoPath?.includes("workspace") || element === "inside") && "36px"};
    font-size: ${({ videoPath, element }) =>
      (!videoPath?.includes("workspace") || element === "inside") && "8px"};
    img {
      width: ${({ videoPath, element }) =>
        (!videoPath?.includes("workspace") || element === "inside") && "9px"};
      height: ${({ videoPath, element }) =>
        (!videoPath?.includes("workspace") || element === "inside") && "9px"};
    }
  }
`;

export const CommentContainer = styled.div`
  font-size: 10px;
  opacity: ${(props) =>
    Math.floor(props.currentTime) === parseInt(props.elementTime) ? 1 : 0};
  display: flex;
  border-radius: 20px;
  background: black;
  padding: 7px 9px;
  gap: 6px;
  position: absolute;
  top: -44px;
  transform: ${({ elementTime, totalTime }) => {
    const percentage = (parseFloat(elementTime) / totalTime) * 100;
    if (percentage > 75) return "translateX(-100%)";
    if (percentage > 25) return "translateX(-50%)";
    return "translateX(0%)";
  }};

  align-items: center;
  // border: 1px solid white;
  transition: opacity 0.5s ease 0.1s;
  max-width: 300px;
  &:hover {
    opacity: 1;
  }
`;

export const ComementUserIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: ${({ userData, element }) =>
    userData?.user?.profileImage || element?.profileImage ? "none" : "#1bbbcf"};

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #1bbbcf;
  }
`;

export const Comment = styled.div`
  width: 70%;
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 17px;
  text-align: left;
`;

export const EmojiNameContainer = styled.div`
  display: flex;
  border-radius: 20px;
  background: black;
  padding: 7px 9px;
  gap: 6px;
  position: absolute;
  top: -35px;
  align-items: center;
  // border: 1px solid white;
  font-size: 10px;
  opacity: 0;
  transition: opacity 0.5s ease 0.1s;
  left: ${({ elementTime, totalTime }) =>
    (parseFloat(elementTime) / totalTime) * 100 > 85
      ? `-${(parseFloat(elementTime) / totalTime) * 40}px`
      : "auto"};
  &:hover {
    opacity: 1;
  }
`;

export const EmojiOrCommentIconWrapper = styled.div`
  font-size: 20px;
  z-index: 999;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }
  }

  &:hover + ${CommentContainer} {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  &:hover + ${EmojiNameContainer} {
    opacity: 1;
    transition: opacity 0.5s ease; /* Show immediately when hovered */
  }
`;

export const EmojiAndComentsWrapperOutsideVideo = styled.div`
  // position: absolute;
  //   top: 63%;
  //   left: 46%;
  display: flex;
  background: black;
  padding: 5px 6px;
  gap: 18px;
  border-radius: 48px;
  align-items: center;
  background: black;
  // border: 1px solid white;
  z-index: 999;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (max-width: 1310px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const DeleteIcon = styled.div`
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 11px;
    height: 11px;
  }
`;

export const FlonnectBanner = styled.div`
  position: absolute;
  right: 163px;
  top: 37%;

  img {
    width: 80px;
    height: 80px;
  }
`;

export const ChapterPointsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 7px;
  left: 0;
  pointer-events: auto;

  gap: 3px;
`;
export const PointsItem = styled.div`
  width: ${(props) => (props?.elementWidth ? props?.elementWidth : "")}%;
  height: 5px;
  background: #fcfcfd;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  opacity: 0.5;
  &:hover {
    transform: scaleY(2);
  }
`;
export const PoitEnd = styled.div`
  width: 2px;
  height: 5px;
  background-color: black;
  position: absolute;
  top: 0;
  right: -4px;
  z-index: 10;
`;

//loader animation code
export const spin = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

// Create styled overlay for the loader
export const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 1;
`;

// Create styled spinner
export const Spinner = styled.div`
  border: 5px solid #ffffff4d;
  border-top: 5px solid #6528f7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;
