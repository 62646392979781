import React from "react";
import DownloadImg from "./download.svg";
import { DownloadNote, RightDiv } from "./Download.styled";
import { useAuth } from "../../context/auth";
import { useVideo } from "../../pages/Dashboard/VideoPlayer/Context/videoPlayerContext";

const Download = ({ progress }) => {
  const videoAuth = useVideo();
  return (
    <DownloadNote upl={videoAuth?.activeNoti}>
      <div className="container">
        <div
          className="outer"
          style={{
            background: `conic-gradient(#6A36F0 ${progress}%,#d9d9d9 0)`,
          }}
        >
          <div className="inner">
            <img src={DownloadImg} alt="" draggable="false"/>
          </div>
        </div>
        <div className="in-progress">Video Downloading...</div>
      </div>

      <RightDiv />
    </DownloadNote>
  );
};

export default Download;
